import {DropdownItem} from "reactstrap"
import classnames from "classnames"
import React, {useMemo, useRef, useState} from "react"
import {toggleNotification} from "../../api/notifications"
import {prettyDate} from "../../helpers/utils"
import BaseActionDropdown from "../../Components/Tables/Actions/BaseActionDropdown"
import BaseTable from "../../Components/Tables/BaseTable"

function NotificationsTable({updateBellNotifications, togglePageButtons, withFilters}) {
  const baseTableMethodsRef = useRef(null)
  const [selectedRows, setSelectedRows] = useState([])

  const selectedRowsHandler = (row) => {
    setSelectedRows(row)
  }

  const markNotificationHandler = (ids) => {
    togglePageButtons(true)
    toggleNotification(ids).then(() => {
      updateBellNotifications()
      updateTable()
    })
  }

  const columns = useMemo(
    () => [
      {
        id: "id",
        name: <span className='font-weight-bold fs-14'>ID</span>,
        selector: row => row.id,
        width: "330px",
      },
      {
        id: "title",
        name: <span className='font-weight-bold fs-14'>Title</span>,
        selector: row => row.data.title,
        width: "100px",
      },
      {
        id: "sub_title",
        name: <span className='font-weight-bold fs-14'>Subtitle</span>,
        selector: row => row.data.sub_title,
        width: "650px",
      },
      // {
      //   id: "message",
      //   name: <span className='font-weight-bold fs-14'>Message</span>,
      //   selector: row => row.data?.message ? row.data?.message : '-',
      // },
      {
        id: "created_at",
        name: <span className='font-weight-bold fs-14'>Created At</span>,
        selector: row => prettyDate(row.created_at),
        width: "170px",
      },
      {
        id: "status",
        name: <span className='font-weight-bold fs-14'>Status</span>,
        selector: row =>
          <span className={`w-100 badge bg-${!!row.read_at ? 'soft-success' : 'soft-warning'}
						text-${!!row.read_at ? 'success' : 'warning'}`}>{!!row.read_at ? 'Read' : 'Unread'}
					</span>,
        width: "100px",
      },
      {
        id: "actions",
        name: <span className='font-weight-bold fs-14'>Actions</span>,
        selector: row =>
          <>
            <button
              type="submit"
              className={classnames('btn', 'btn-sm', 'w-100', {
                'btn-warning': !!row.read_at,
                'btn-success': !row.read_at
              })}
              onClick={() => markNotificationHandler([row.id])}
            >
              {!!row.read_at ? "Mark as unread" : "Mark as read"}
            </button>
          </>
      },
    ], [markNotificationHandler])

  const updateTable = () => {
    baseTableMethodsRef.current.updateTable()
  }

  const contextActions = (
    <BaseActionDropdown key="actionsDropdown">
      <DropdownItem onClick={() => {
        markNotificationHandler(selectedRows.map(i => i.id))
      }}>
        Mark as read
      </DropdownItem>
      <DropdownItem onClick={() => {
        markNotificationHandler(selectedRows.map(i => i.id))
      }}>
        Mark as unread
      </DropdownItem>
    </BaseActionDropdown>
  )

  return (
    <BaseTable
      columns={columns}
      filterFields={withFilters ? [] : []}
      title="Notifications"
      url="/spa/notifications"
      methodsRef={baseTableMethodsRef}
      actions={contextActions}
      selectedRowsHandler={selectedRowsHandler}
    />
  )
}

export default NotificationsTable
